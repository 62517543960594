<template>
  <div class="p-grid">
    <div style="width:20rem; margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff">
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
        <h3 class="p-m-0">
          <Button v-if="!isEditable" v-tooltip.bottom="_t('label_add_new')"
                  class="p-button-rounded p-button-success p-button-text" icon="pi pi-plus" style="float: right;"
                  @click="initNewUserDialog()"/>
          <ChildFilter ref="cf" v-model="users" :orgId="orgId" style="float: right;"/>
          <strong>{{ _t('label_users') }}</strong>
        </h3>
        <MultiSelectableBreadcrumb ref="breadcrumbData" single-selection></MultiSelectableBreadcrumb>
      </div>
      <div>
        <span class="p-input-icon-right p-m-3" style="width: calc(100% - 3rem)">
          <i class="pi pi-search" style="font-size: 12px;"/>
          <InputText v-model="userSearch" style="width:100%; margin: 5px;" type="text"/>
        </span>
      </div>
      <DataTable
          ref="tree"
          v-model:selection="selectedNode"
          :dataKey="tableDataKey"
          :lazy="true"
          :page="currentPage"
          :paginator="true"
          :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
          :rowHover="true"
          :rows="userTableRows"
          :scrollable="true"
          :totalRecords="totalRecords"
          :value="users"
          class="tight"
          scrollHeight="75vh"
          selectionMode="single"
          stripedRows
          @page="selectTablePage($event)"
          @row-click="this.selectUser($event.data.id);"
      >
        <Column :expander="true"
                :header="_t('label_full_name')"
                field="name"
                style="width: 100%; word-break: break-all;min-height: 38px;"
        >
          <template #body="{data}">
                <span class="wrap"
                      style="word-break: break-all; padding-left: 5px;">
              {{ data.surname }} {{ data.name }}
               </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <transition name="slide-fade">
      <Card v-show="selectedUser.id!== null" class="p-col">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <div>
              <h3 class="p-m-0">
                {{ _t('label_user') }}
                <strong>
                  {{ selectedUser.name }} {{ selectedUser.surname }}
                </strong>
              </h3>
            </div>
            <div>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times"
                      @click="selectUser(selectedUser.id)"/>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Save')"
                      class="p-button-rounded p-button-text p-button-success"
                      icon="pi pi-check"
                      @click="saveUser()"
              />
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Edit')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-pencil"
                      @click="editUser()"
              />
              <Button
                  v-if="!isEditable"
                  v-tooltip.bottom="_t('Clone')"
                  class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-clone"
                  @click="initCloneUserDialog()"
              />
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Delete')"
                      class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash"
                      @click="deleteUserDialog = true"
              />
            </div>
          </div>
        </template>
        <template #content>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="card">
                <TabView :disabled="selectedUser.id === null">
                  <TabPanel
                      :disabled="selectedUser.id === null"
                      :header="_t('menu.general')"
                  >
                    <div class="p-grid">
                      <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                        <p>{{ _t('label_name') }}</p>
                      </div>
                      <div class="p-col-7 stripedBg" style="vertical-align: middle;">
                        <p v-if="!isEditable || selectedUser.login === 'admin'"><strong>{{
                            selectedUser.name ?? '...'
                          }}</strong></p>
                        <div v-else class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi pi-pencil"></i>
                          </span>
                          <InputText v-model="selectedUser.name" class="p-md-12" type="text"/>
                        </div>
                      </div>
                      <div class="p-col-5" style="vertical-align: middle;">
                        <p>{{ _t('label_surname') }}</p>
                      </div>
                      <div class="p-col-7" style="vertical-align: middle;">
                        <p v-if="!isEditable || selectedUser.login === 'admin'"><strong>{{
                            selectedUser.surname ?? '...'
                          }}</strong></p>
                        <div v-else class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi pi-pencil"></i>
                          </span>
                          <InputText v-model="selectedUser.surname" class="p-md-12" type="text"/>
                        </div>
                      </div>
                      <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                        <p>{{ _t('label_username') }}</p>
                      </div>
                      <div class="p-col-7 stripedBg" style="vertical-align: middle;">
                        <p v-if="!isEditable || selectedUser.login === 'admin'"><strong>{{
                            selectedUser.login ?? '...'
                          }}</strong>
                        </p>
                        <div v-else class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi pi-pencil"></i>
                          </span>
                          <InputText v-model="selectedUser.login" class="p-md-12" type="text"/>
                        </div>
                      </div>
                      <div class="p-col-5" style="vertical-align: middle;">
                        <p>{{ _t('label_personal_id') }}</p>
                      </div>
                      <div class="p-col-7" style="vertical-align: middle;">
                        <p v-if="!isEditable || selectedUser.login === 'admin'">
                          <strong>
                            {{
                              selectedUser.personalId === null || selectedUser.personalId === "" ?
                                  '...' :
                                  selectedUser.personalId
                            }}
                          </strong>
                        </p>
                        <div v-else class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi pi-pencil"></i>
                          </span>
                          <InputText v-model="selectedUser.personalId" class="p-md-12" type="text"/>
                        </div>
                      </div>
                      <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                        <p>{{ _t('label_language') }}</p>
                      </div>
                      <div class="p-col-7 stripedBg" style="vertical-align: middle;">
                        <p v-if="!isEditable"><strong>{{ selectedUser.language }}</strong></p>
                        <div v-else class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi pi-globe"></i>
                          </span>
                          <Dropdown v-model="selectedUser.language"
                                    :options="languages"
                                    class="p-md-12"
                                    optionLabel="label"
                                    optionValue="value"
                                    style="padding: 0px;"
                          />
                        </div>
                      </div>
                      <div class="p-col-5" style="vertical-align: middle;">
                        <p>{{ _t('label_organization') }}</p>
                      </div>
                      <div class="p-col-7" style="vertical-align: middle;">
                        <OrganizationLink v-model="selectedUser"
                                          :editMode="isEditable && selectedUser.login !== 'admin'"/>
                      </div>
                      <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                        <p>{{ _t('label_2FA') }}</p>
                      </div>
                      <div class="p-col-7 stripedBg" style="vertical-align: middle; padding-top: 0; padding-bottom: 0;">
                        <Button
                            v-tooltip.top="isSetResetToggle ? _t('label_Display_QR_code') : _t('label_reset_2fa_participant')"
                            :label="isSetResetToggle ? _t('label_Display_QR_code') : _t('label_reset_2fa_participant')"
                            class="p-button p-button-primary"
                            @click="handleClickSetReset"/>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                      :disabled="selectedUser.id === null"
                      :header="_t('label_Desktop_access')"
                  >
                    <div class="p-grid">
                      <div class="p-col-4 stripedBg">
                        <p>{{ _t('label_username') }}</p>
                      </div>
                      <div class="p-col-8 stripedBg">
                        <p v-if="!isEditable || selectedUser.login === 'admin'"><strong>{{
                            selectedUser.login
                          }}</strong></p>
                        <div v-else class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi pi-key"></i>
                          </span>
                          <InputText v-model="selectedUser.login" class="p-md-12" type="text"/>
                        </div>
                      </div>
                      <div class="p-col-4">
                        <p>{{ _t('label_group_name') }}</p>
                      </div>
                      <div class="p-col-8">
                        <div v-if="isEditable && selectedUser.login !== 'admin'" class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi-users"></i>
                          </span>
                          <Dropdown v-model="selectedUser.userGroupId"
                                    :filter="true"
                                    :options="userGroups"
                                    class="p-m-0"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Select a role"
                          />
                          <Button class="p-button-danger" style="margin: 2px;" @click="selectedUser.userGroupId = 0;">
                            <i class="pi pi-trash"></i>
                          </Button>

                        </div>
                        <Button v-else :label="makeUserGroupName()"
                                class="p-button-link text"
                                @click="selectedUser.userGroupId !==null ? toUserGroup(selectedUser.userGroupId): null"/>
                      </div>
                      <div class="p-col-4 stripedBg">
                        <p>{{ _t('label_password') }}</p>
                      </div>
                      <div class="p-col-8 stripedBg">
                        <Button
                            :disabled="!isEditable"
                            :label="_t('label_change_pass')"
                            class="p-button-outlined p-button-danger p-button-sm"
                            style="font-size: 12px;"
                            @click="changePassDlg = true"
                        />
                      </div>
                    </div>
                    <div v-if="originalUserGroupObject">
                      <h3 class="subhead p-mt-5">
                        {{ originalUserGroupObject ? originalUserGroupObject.name : '' }}
                        {{ _t('label_effective_role_permission') }}
                      </h3>
                      <TabView>
                        <TabPanel :header="_t('permissions')">
                          <div class="p-grid">
                            <div v-for="globalPermission of globalPermissions" :key="globalPermission"
                                 class="p-field-checkbox p-col-12 p-md-4">
                              <Checkbox :id="'gl_p_'+globalPermission"
                                        v-model="originalUserGroupObject.generalPermissions"
                                        :disabled="true"
                                        :value="globalPermission"
                                        name="globalPermission"/>
                              <label :for="'gl_p_'+globalPermission">{{ formatTitle(globalPermission) }}</label>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel :header="_t('admin_permissions')">
                          <div class="p-grid">
                            <div v-for="adminPermission of adminPermissions" :key="adminPermission"
                                 class="p-field-checkbox p-col-12 p-md-4">
                              <Checkbox :id="'adm_p_'+adminPermission"
                                        v-model="originalUserGroupObject.adminPermissions"
                                        :disabled="true"
                                        :value="adminPermission"
                                        name="adminPermission"/>
                              <label :for="'adm_p_'+adminPermission">{{ formatTitle(adminPermission) }}</label>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel :header="_t('label_access_rights')">
                          <TreeTable
                              v-model:selectionKeys="selectedTreeTableValue"
                              :value="organizations"
                              autoLayout
                              class="p-treetable-sm"
                              @node-expand="appendNode($event)"
                          >
                            <Column :expander="true" :header="_t('label_object_name')" field="name"/>
                            <Column v-for="item in accessRights" :key="item" :field="item">
                              <template #header>
                                <Button v-tooltip.top="formatTitle(item)" :icon="icons[item.toLowerCase()]"
                                        class="p-button-link" style="color: black;">
                                </Button>
                              </template>
                              <template #body="slotProps">
                                <Button
                                    v-tooltip.bottom="formatTitle(slotProps.node.data.rights[item])"
                                    :disabled="true"
                                    :icon="actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null'].icon"
                                    :style="`color: ${actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null' ].color};`"
                                    class="p-button-text"
                                />
                              </template>
                            </Column>
                          </TreeTable>
                        </TabPanel>
                      </TabView>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </transition>
  </div>
  <Dialog v-model:visible="changePassDlg" style="width: 30rem;">
    <template #header>
      <h3>{{ _t('label_change_pass') }}</h3>
    </template>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-key"></i>
      </span>
      <InputText v-model="selectedUser.password" :placeholder="_t('label_password')" type="password"/>
    </div>
    <div class="p-inputgroup p-mt-2">
      <span class="p-inputgroup-addon">
        <i class="pi pi-key"></i>
      </span>
      <InputText v-model="selectedUser.password_repeat" :placeholder="_t('label_pls_repeat_pass')" type="password"/>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="changePassDlg = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="saveUser(); changePassDlg = false"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="newUserDialog" :style="{width: '450px'}">
    <template #header>
      <h3 v-if="cloneUser">{{ _t('label_clone_user') }}</h3>
      <h3 v-else>{{ _t('label_create_new_user') }}</h3>
    </template>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-6 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-pencil"></i>
          </span>
          <InputText v-model="newUser.name" :placeholder="_t('label_name')" autocomplete="off"/>
        </div>
        <small v-if="userCreateError.includes('name')" class="p-error">{{ _t('lbl_full_name_required') }}</small>
      </div>
      <div class="p-col-6 p-field">
        <InputText v-model="newUser.surname" :placeholder="_t('label_surname')" autocomplete="off" required/>
        <small v-if="userCreateError.includes('surname')" class="p-error">{{ _t('fullname_required') }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi pi-pencil"></i>
          </span>
          <InputText v-model="newUser.login" :placeholder="_t('label_username')" autocomplete="off"/>
        </div>
        <small v-if="userCreateError.includes('login')" class="p-error">{{ _t('label_username_required') }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-key"></i>
          </span>
          <InputText v-model="newUser.password" :placeholder="_t('label_password')" autocomplete="off" type="password"/>
        </div>
        <small v-if="userCreateError.includes('password')" class="p-error">{{ _t('password_required') }}</small>
      </div>
    </div>

    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-6 p-field">
        <div class="p-inputgroup">
          <Dropdown
              v-model="newUser.language"
              :options="languages"
              :placeholder="_t('label_language')"
              optionLabel="label"
              optionValue="value"
          />
        </div>
      </div>
      <div class="p-col-6 p-field">
        <InputText v-model="newUser.personalId" :placeholder="_t('label_personal_num')" type="text"/>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <Dropdown
            v-model="newUser.userGroupId"
            :filter="true"
            :options="userGroups"
            :placeholder="_t('label_group_name')"
            optionLabel="name"
            optionValue="id"
        />
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <OrganizationLink v-model="newUser" :editMode="true" :required-field="true"/>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newUserDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createUser()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteUserDialog">
    <template #header>
      <h3>{{ _t('label_delete') }}</h3>
    </template>
    <p><strong>{{ selectedUser.name }} {{ selectedUser.surname }}</strong> {{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="deleteUserDialog = false"/>
      <Button :label="_t('Delete')" autofocus class="p-button-success" @click="deleteUser()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="isSet2faModal"
          :header="!temp2faAuth.qrCodePath ? _t('label_password_confirm') : _t('label_scan_qr_code')" modal
          style="width: 30vw;">
    <div v-if="!temp2faAuth.qrCodePath"
         style="width: 50%; margin: 0 auto; display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <InputText v-model="temp2faAuth.setPasswordData" :placeholder="_t('label_password')" style="width: 100%; margin-bottom: 20px;"
                 type="password"/>
      <Button :disabled="!temp2faAuth.setPasswordData" :label="_t('label_Display_QR_code')" style="width: 100%;"
              @click="set2faParticipant"/>
    </div>
    <div v-else>
      <div style="width: 100%; display: flex; justify-content: center;">
        <img :src="temp2faAuth.qrCodePath" alt="qr_code"/>
      </div>
      <br>
      <InputText v-model="temp2faAuth.code" :placeholder="_t('label_enter_otp')"
                 style="width: 100%; margin-bottom: 20px;"/>
      <br>
      <Button :label="_t('label_Display_QR_code')" style="width: 100%;" @click="auth2faParticipant"/>
    </div>
  </Dialog>
</template>

<script>
import router from '@/router';
import {getList} from '@/api/list'
import {getTree} from "@/api/tree";
import {createUser, deleteUser, getUser, getUserByLogin, updateUser} from "@/api/user";
import {findUserGroup, getAccessRights, getAdminPermissions, getPermissions, getUGTree} from '@/api/userGroup'
import {getParticipantByLogin, updateParticipant} from "@/api/participant";
import MultiSelectableBreadcrumb from "../../components/ixarma/MultiSelectableBreadcrumb";
import OrganizationLink from '../../components/ixarma/OrganizationLink'
import ChildFilter from '../../components/ixarma/ChildFilter'
import {generateQrUrl, get2faStatus, login, reset2faStatus} from "@/api/auth";

export default {
  name: 'index',
  components: {MultiSelectableBreadcrumb, OrganizationLink, ChildFilter},
  data() {
    return {
      userSearch: '',
      isEditable: false,
      changePassDlg: false,
      userGroups: null,
      users: null,
      selectedNode: null,
      totalRecords: 100,
      userTableRows: 100,
      totalUGRecords: 100,
      currentPage: 0,
      currentUGPage: 0,
      loading: true,
      dataTabs: false,
      languages: [],
      statuses: [
        'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
      ],
      selectedTreeTableValue: null,
      selectedUser: {
        id: null,
        name: '',
        surname: '',
        login: '',
        personalId: '',
        language: 'GERMAN',
        isUser: true,
        isAlarmParticipant: true,
        userGroupId: null
      },
      newUserDialog: false,
      cloneUser: false,
      newUser: {
        name: '',
        surname: '',
        login: '',
        password: '',
        password_repeat: '',
        language: 'GERMAN',
        orgName: '...',
        orgId: 1,
        personalId: '',
        type: 'CUSTOM',
        version: 0,
        userGroupId: null
      },
      treeParams: 'category_filter=GEOGRAPHICAL_SCOPE',
      deleteUserDialog: false,
      globalPermissions: [],
      adminPermissions: [],
      accessRights: [],
      originalOrganisationsObject: [],
      originalUserGroupObject: null,
      organizations: [],
      userCreateError: [],
      icons: {
        user: 'pi pi-fw pi-user',
        user_group: 'pi pi-fw pi-users',
        participant: 'pi pi-fw pi-user-minus',
        participant_group: 'pi pi-fw pi-users',
        participant_rotational_cal: 'pi pi-fw pi-tablet',
        participant_individual_cal: 'pi pi-fw pi-mobile',
        alarm_point: 'pi pi-fw pi-bell',
        alarm_point_rotational_cal: 'pi pi-fw pi-calendar-times',
        alarm_point_individual_cal: 'pi pi-fw pi-calendar-plus',
        alarm_plan: 'pi pi-fw pi-map',
        organization: 'pi pi-fw pi-globe',
        holiday: 'pi pi-fw pi-calendar-minus',
        device: 'pi pi-fw pi-desktop'
      },
      actions: {
        read: {
          icon: 'pi pi-fw pi-eye',
          color: 'blue'
        },
        write: {
          icon: 'pi pi-fw pi-pencil',
          color: 'green'
        },
        none: {
          icon: 'pi pi-fw pi-times',
          color: 'red'
        },
        null: {
          icon: '',
          color: 'grey'
        }
      },
      orgId: localStorage.getItem('orgId') ?? 1,
      checkOrgIdIntervalId: null,
      isSet2faModal: false,
      isSetResetToggle: false,
      temp2faAuth: {
        setPasswordData: '',
        userLogin: '',
        qrCodePath: '',
        code: ''
      },
    }
  },
  mounted() {
    this.languages = [
      {value: 'ENGLISH', label: this._t('ENGLISH')},
      {value: 'GERMAN', label: this._t('GERMAN')},
      // {value: 'FRENCH', label: this._t('FRENCH')},
      {value: 'ITALIAN', label: this._t('ITALIAN')},
      {value: 'CZECH', label: this._t('CZECH')},
      // {value: 'GREEK', label: this._t('GREEK')},
      // {value: 'SPANISH', label: this._t('SPANISH')}
    ];
    this.selectedUser = {
      id: null,
      name: '',
      surname: '',
      login: '',
      personalId: '',
      _password: '',
      language: 'GERMAN',
      isUser: true,
      isAlarmParticipant: true,
      userGroupId: null
    };
    this.getUsers()
    this.getUserGroups()
    this.getAdminPermissions()
    this.getAccessRights()
    this.getPermissions()

    this.checkOrgId()

    let userId = this.$route.params.id
    if (userId) {
      this.selectUser(userId)
    }
  },
  beforeUnmount() {
    clearInterval(parseInt(this.checkOrgIdIntervalId))
  },
  watch: {
    userSearch() {
      this.getUsers()
    },
    newUserDialog(value) {
      if (value === false) {
        this.selectUser(this.selectedUser.id)
      }
    },
    'selectedUser.userGroupId': function (value) {
      if (value !== null) {
        this.findUserGroup(value)
      }
    }
  },
  methods: {
    reset2faParticipant() {
      reset2faStatus(this.selectedUser.login).then((res) => {
        if (res.status === 200) {
          this.$toast.add({
            severity: 'success',
            summary: 'Successful',
            detail: this._t('label_reset_2fa_participant_success'),
            life: 3000
          })
          this.isSetResetToggle = true
        }
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Successful',
          detail: this._t(error.response.data.reason),
          life: 3000
        })
      })
    },
    makeUserGroupName() {
      let item
      if (this.userGroups && this.selectedUser.userGroupId) {
        item = this.userGroups.find(o => o.id === this.selectedUser.userGroupId)
      }
      return item ? item.name : '...'
    },
    toggleSearch(event) {
      this.$refs.op.toggle(event);
    },
    selectUser(id) {
      if (!id) {
        this.selectedUser.id = null
        return
      }
      getUser(id).then((response) => {
        this.selectedUser = response.data
        this.selectedUser._login = response.data.login
        this.selectedUser._password = response.data.password
        this.selectedUser.password = null
        this.selectedUser.isUser = response.data.userGroupId !== null
        this.getUserGroups()
        if (this.selectedUser.userGroupId !== null) {
          this.findUserGroup(this.selectedUser.userGroupId)
        }
        this.selectedUser.isAlarmParticipant = true
        this.dataTabs = true
        this.isEditable = false

        get2faStatus(response.data.login).then((res) => {
          if (res.data && res.data.status === 'NONE') {
            this.userLogin = response.data.login
            this.isSetResetToggle = true
          } else {
            this.isSetResetToggle = false
          }
        })
      })
    },
    handleClickSetReset() {
      this.temp2faAuth.qrCodePath = ''
      this.temp2faAuth.setPasswordData = ''
      if (this.isSetResetToggle) {
        this.isSet2faModal = true
      } else {
        this.reset2faParticipant()
      }
    },
    set2faParticipant() {
      this.temp2faAuth.userLogin = this.selectedUser.login
      generateQrUrl({username: this.temp2faAuth.userLogin, password: this.temp2faAuth.setPasswordData})
          .then((response) => {
            this.temp2faAuth.qrCodePath = response.data
          })
          .catch(error => {
            this.$toast.add({
              severity: 'error', summary: 'Successful',
              detail: this._t(error.response.data.reason), life: 3000
            })
          })
    },
    auth2faParticipant() {
      login({
        username: this.temp2faAuth.userLogin,
        password: this.temp2faAuth.setPasswordData,
        verificationCode: this.temp2faAuth.code
      })
      this.isSet2faModal = false
      this.selectUser(this.selectedUser.id)
      this.$toast.add({
        severity: 'success', summary: 'Successful',
        detail: '',
        life: 3000
      })
    },
    editUser() {
      this.isEditable = true
    },
    saveUser() {
      if (this.selectedUser.password && this.selectedUser.password.length > 0) {

        if (!this.selectedUser.password_repeat || !this.selectedUser.password_repeat.length) {
          this.$root.showMessage(this._t('label_pls_repeat_pass'), 'error')
          return false
        }

        if (this.selectedUser.password !== this.selectedUser.password_repeat) {
          this.$root.showMessage(this._t('label_pass_not_match'), 'error')
          return false
        }
      } else {
        this.selectedUser.password = this.selectedUser._password
        delete this.selectedUser._password
      }
      const originalLogin = this.selectedUser._login

      getUserByLogin(this.selectedUser.login).then((response) => {
        if (response.data && response.data.id != this.selectedUser.id) {
          this.$root.showMessage(this._t('label_user_exists'), 'error')
        } else {
          updateUser(this.selectedUser).then((response) => {
            this.selectedUser = response.data
            this.selectedUser._login = response.data.login
            this.selectedUser._password = response.data.password
            delete this.selectedUser.password
            this.selectedUser.isUser = response.data.userGroupId !== null
            this.selectedUser.isAlarmParticipant = true
            this.updateLinkedParticipant(originalLogin)
            this.dataTabs = true
            this.isEditable = false
            this.getUsers()
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? data.reason : this._t('TECHNICAL_ERROR')), 'error')
          })
        }
      })
    },
    updateLinkedParticipant(login) {
      getParticipantByLogin(login).then((response) => {
        if (response.data.id) {
          let participant = response.data

          participant.name = this.selectedUser.name
          participant.surname = this.selectedUser.surname
          participant.login = this.selectedUser.login
          participant.userGroupId = this.selectedUser.userGroupId
          participant.orgId = this.selectedUser.orgId
          participant.personalNumber = this.selectedUser.personalId && this.selectedUser.personalId.length ? this.selectedUser.personalId : null
          if (this.selectedUser.password && this.selectedUser.password.length) {
            participant.password = this.selectedUser.password
          }
          if (participant.media && participant.media.length > 0) {
            participant.media = participant.media.map(item => {
              if (item.callingNumber === login) {
                item.callingNumber = this.selectedUser.login
              }
              return item;
            })
          }
          updateParticipant(participant).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? data.reason : this._t('TECHNICAL_ERROR')), 'error')
            return false
          })
        }
      })
    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.getUsers()
        }
      }, 100);
    },
    createUser() {
      if (!this.newUser.login || !this.newUser.login.length) {
        this.$root.showMessage(this._t('sername_required'), 'error')
        return false
      }
      if (!this.newUser.password || !this.newUser.password.length) {
        this.$root.showMessage(this._t('password_required'), 'error')
        return false
      }

      getUserByLogin(this.newUser.login).then((response) => {
        if (response.data) {
          this.$root.showMessage(this._t('label_user_exists'), 'error')
          this.addSystemAccessDialogForceNew = true
        } else {
          if (this.cloneUser === true) {
            delete this.newUser.id
          }
          createUser(this.newUser)
              .then((response) => {
                this.selectedUser = response.data
                this.selectedUser._login = response.data.login
                this.selectedUser.isUser = response.data.userGroupId !== null
                this.selectedUser.isAlarmParticipant = true
                this.newUserDialog = false
                this.cloneUser = false
                this.dataTabs = true
                this.isEditable = false
                this.getUsers()
                this.selectedNode = null
              }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage(data.reason ? data.reason : this._t('TECHNICAL_ERROR'), 'error')
          })
        }

      })
    },
    getUsers() {
      getList('USER', this.currentPage, this.userSearch, this.userTableRows).then((response) => {
        this.users = response.data.list
        this.users.sort((a, b) => (a.surname + ' ' + a.name).localeCompare(b.surname + ' ' + b.name))
        this.currentPage = response.data.currentPage
        this.totalRecords = response.data.count
        this.loading = false
        this.$refs.cf.refresh(this.users)
      })
    },
    selectTablePage(item) {
      this.currentPage = item.page
      this.getUsers()
    },
    getUserGroups() {
      getList('USERGROUP', 0, '', 10000).then((response) => {
        this.userGroups = response.data.list
        this.currentUGPage = response.data.currentPage
        this.totalUGRecords = response.data.count
        this.loading = false

      })
    },
    toUserGroup(id) {
      router.push(`/user-groups/${id}`)
    },
    initNewUserDialog() {
      this.newUserDialog = true
      this.newUser = {
        name: '',
        surname: '',
        login: '',
        password: '',
        language: 'GERMAN',
        orgName: '...',
        orgId: localStorage.getItem('orgId') ?? 1,
        personalId: '',
        type: 'CUSTOM',
        version: 0,
        userGroupId: null
      }
    },
    initCloneUserDialog() {
      this.newUser = this.selectedUser
      this.newUser.version = 0
      this.newUser.surname = '[clone]_' + (this.newUser.surname ?? '')
      this.newUser.login = (this.newUser.login ?? '') + '_[clone]'

      this.cloneUser = true
      this.newUserDialog = true
    },
    deleteUser() {
      this.deleteUserDialog = false
      deleteUser(this.selectedUser.id).then(() => {
        this.selectedUser = {
          id: null,
          name: '',
          surname: ''
        }
        this.getUsers()
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ?? this._t('TECHNICAL_ERROR')), 'error')
      })
    },
    findUserGroup(id) {
      findUserGroup(id).then((response) => {
        this.originalUserGroupObject = this.userGroup = response.data;
        this.originalUserGroupObject.accessRightsMapped = this.originalUserGroupObject.accessRights.map(item => {
          const container = {data: {}};
          container.data.name = item.treeNode.name;
          this.companyId = item.treeNode.companyId
          for (const right of item.rights) {
            container.data[right.category] = right.value
          }
          container.leaf = !item.hasChildren
          container.key = item.treeNode.id
          return container;
        })
        this.getUserGroupOrganizations()
      })
    },
    getUserGroupOrganizations() {
      getUGTree(this.originalUserGroupObject.orgId).then((response) => {
        this.originalOrganisationsObject.push(response.data)
        response.data = [response.data]
        this.organizations = response.data.map(item => {
              return this.mapAccessRights(item);
            }
        )
      })
      this.$forceUpdate()
    },
    getAccessRights() {
      this.accessRights = []
      getAccessRights().then((response) => {
        const groups = response.data
        for (const group in groups) {
          this.accessRights = this.accessRights.concat(groups[group])
        }
      });
    },
    getPermissions() {
      getPermissions().then((response) => {
        this.globalPermissions = response.data
      });
    },
    getAdminPermissions() {
      getAdminPermissions().then((response) => {
        this.adminPermissions = response.data
      });
    },
    mapAccessRights(item) {
      const container = {
        data: {
          rights: []
        }
      };
      container.data.name = item.name;

      for (const key of Object.values(this.accessRights)) {
        container.data.rights[key] = null
      }

      const accessRights = this.originalUserGroupObject.accessRightsMapped
      for (const accessRight of accessRights) {
        if (accessRight.key === item.id) {
          container.data.rights = accessRight.data
        }
      }
      container.leaf = !item.hasChildren
      container.key = item.id;
      return container;
    },
    formatTitle(title) {
      const labelsWithPrefix = ['REPORTS', 'MANUAL_CALL', 'ALARM_EVENT_RULE', 'INSTRUCTION_FILES'];
      const labelsFromMenu = ['IMPORT'];
      if (!title) return ''
      if (labelsWithPrefix.indexOf(title) !== -1) {
        title = 'label_' + title.toLowerCase()
      }
      if (labelsFromMenu.indexOf(title) !== -1) {
        title = 'menu.' + title.toLowerCase()
      }
      if (title === 'NONE') {
        title = '_NONE'
      } else if (title === 'NULL') {
        title = 'label_nil'
      }
      return this._t(title)
    },
    appendNode(item) {
      getTree(item.key).then((response) => {
        for (const org of response.data) {
          this.originalOrganisationsObject.push(org)
        }
        item.children = response.data.map(item => {
          return this.mapAccessRights(item);
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;

      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {

            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #C63737;
}

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 5px;
  }

  .p-treetable-thead > tr > th {
    padding: 10px;
  }

}

.customer {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customer td, .customers th {
  border: 1px solid #ddd;
  padding: 10px;
}

.customer tr:nth-child(even) {
  background-color: #f2f2f2;
}

.text {
  font-size: 12px;
  padding: 0;
}
</style>
